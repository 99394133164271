import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Hydrographics",
  "description": "Dipping objects in a solvent mixture to add patterned/full colors like checkers, camo etc.",
  "author": "Orkun Zafer Ozturk",
  "categories": ["mechanical"],
  "date": "2022-01-01T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["Silkscreen-Printing", "Hot-Stamping", "Powder-Coating"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hydrographics, unlike silkscreen, serve for more variety in shapes. The process is done by printing the special hydrographic cover film (could be colored or in patterns) on top of the solvent, before dipping the object on the floated film. The film will evenly cover the workpiece's outer surface, following its shape.`}</p>
    <p>{`The hydrographic coating could last for `}<strong parentName="p">{`more than 15 years`}</strong>{`. However, the film is rather prone to tearing when scratched. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      